.getin_containerFluid{
  margin-top: 2rem;
}
.getin_content_container{
  width: 80%;
  box-shadow: 0px 3px 2px 1px gray;
  padding: 2% 0px;
  border-radius: 20px;
}
.center_heading_div{
  min-width: 80%;  
}
.connectform_wrapper2{
    width: 18%;
    display: flex;
    justify-content: center;
    align-items: center;
font-size: 14px;
margin: auto;
}
.connectform_links{
    text-transform: capitalize;
 text-decoration: none;
 color: #fff;
}
.connectform_links:hover{
color: #EBC770;
}
.connectform_errorbox{
    margin-right: 2px;
    border: none;
   
    }
.custom_button{
    margin-left: -100px;
    margin-right: 10px;
    margin-top: -10px;
    border: none;
    outline: none;
    border-radius: 30px;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    padding: 5px 10px;
    background-color: #3C427B;
  }
.connectform_field_group {
    display: flex;
    justify-content: center;
align-items: center;
    margin: auto;
   width: 275px;
 margin: 2rem auto 0px 0px;
    position: relative;
    border: none;
    border-radius: 30px;
    outline: none;
    color: #000 !important;
    }
  .connectform_form_fields {
    padding-top: 15px;
    padding-left: 10px;
    height: 38px;
    width: 100%;
   background-color: #fff;
   color: #000 !important;
  border: none;
  outline: 1px solid #7177AE;
  border-radius: 30px;
  margin-right: auto;
  }

  .connectform_form_fields:focus~label,
.connectform_form_fields:valid~label {
  padding-top: 2.5px;
  top: 0px;
  left: 20px;
  font-size: 12px;
  border: none;

}
.connectform_form_fields:hover{
  color: #000 !important;
  border: none;
  outline: 1px solid #3C427B;
  background-color: none;
 }

.connectform_form_labels {
   position: absolute;
    top: 12px;
    left: 15px;
    font-size: 15px;
   pointer-events: none;
    transition: 0.2s;
  color: #000;
  }
  .error {
    color: red;
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
  }
  .connectform_brand{
    margin-top: -15px;
    height: 100%;
    width: 125px;
  }
  @media (max-width: 767px) {
    .connectform_wrapper2{
    
    }
    .getin_content_container{
      width: 95%;
      padding: 2%;
    }
    .center_heading_div{
      min-width: 100%;  
    }
    .connectform_field_group {
   margin: 5px auto 10px auto;
   max-width: 80%;
 }
 .custom_button{
  font-size: 11px;
}
}