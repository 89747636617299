.course_container_fluid{
    margin-top: 2rem;
}
.courses_content_container{
margin: 1.5rem auto 0px auto;
}
.course_slider {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding: 1%;
    margin-left: 5%;
}
.course_card {
    max-width: 80%;
    background-color: transparent;
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    height: 100%;
    padding: 0px;
    margin: 0px auto 20px auto; /* Center horizontally within the slider */
    box-shadow: 0px 2px 2px 0px gray;
}
.course_subheading_div{
    font-size: 18px;
    width: 70%;
}

.course_card_img{
    width: 100%;
    height: 90%;
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    padding: 0px;
    margin: 0px;
}
.course_card_title{
    font-weight: 800;
    font-size: 22px;
}

@media (max-width: 767px) {
    .course_container_fluid{
        margin-top: 1rem;
    }
    .courses_content_container{
        width: 90%;
        margin: 1rem auto 0px auto;
    }
    .course_subheading_div{
        width: 100%;
        margin-top: -5px;
        font-size: 13.5px;
        font-weight: 500;
    }
.course_card{
     min-width: 100%;
     margin: 0px auto 20px auto;
      
   }
   .course_card_title{
    font-weight: 600;
    font-size: 16px;
}
  .course_slider{
    width: 100%;
    margin: auto;
   }

}