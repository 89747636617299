.home_container_fluid {
  height: 100%;

}
.home_content_container{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: #fff;
}
.home_cards_container{
    display: flex;
  justify-content: center;
    align-items: center;
   margin-top: -10%;
   gap: 5%;
  
  }
  /*  */
  .home_points_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    margin-left: auto;
    width: 50%;
  }
  .spcl_page_heading{
    font-size: 30px;
    font-weight: 700;
  }
  .spcls_subheadings{
    font-size: 20px;
font-weight: 700;
  }
  .spcls_text{
    font-size: 14px;
    font-weight: 500;
      }
  .home_special_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5rem;
  }
  .spcl_img_wrapper{
  margin: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
.spcl_img_{
  width: 100%;
}
.spcl_icons{
width: 50px;
 height: 50px;
 margin-right: 20px;
}
.home_banner_wrapper{
  margin-top: -3.5%  !important;
}

@media (max-width: 767px) {
.home_banner_wrapper{
  margin-top: 0px  !important;
}
.home_cards_container{
  display: flex;
justify-content: center;
  align-items: center;
 margin-top: -14.5%;
 gap: 2.5%;
}
/*  */
.home_special_container{
  flex-direction: column;
  justify-content: center;
  margin: 5rem auto 0px auto;
  gap: 2rem;
  width: 100%;
  padding: 0px 15px;
}
.spcl_img_wrapper{
  margin: auto;
}
 .spcl_img_{
  width: 90%;
  margin: auto;
}
.home_points_wrapper{
  margin: auto !important;
  width: 100%;
}
.spcl_page_heading{
  font-size: 16px;
}
.spcls_subheadings{
  font-size: 14px;
 }
.spcls_text{
  font-size: 12.5px;
}

}
@media (min-width: 768px) and (max-width: 1024px) {
  
}