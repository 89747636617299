
li {
    list-style: none;
   
  }
  body{
      margin-top: 4.5rem;
  }
  .nav {
       display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 1.5rem;
      height: auto;
      background: #3C427B;
      color: #fff;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 1000; /* Add a higher z-index value */
    }
  .nav__brand {
  height: 80px;
  }
  
  .nav__menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 3.5rem;
    margin-top: 9px;
    
  }
  .nav__item{
    font-weight: 800;
    font-size: 18px;
    
  }
  
  .nav_link{
      color: #fff;
      text-decoration: none;
  }
  .nav_link:hover{
    color: lightgray
  }
  .nav_link:active{
    color: lightgray
  }
  .nav__toggler {
    display: none;
  }
  
  .nav__toggler div {
    width: 2rem;
    height: 0.2rem;
    margin: 0.4rem;
    background: rgb(204, 204, 204);
    transition: 0.3s ease-in;
  }
  .nav_cart{
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
  }
  .dropdown-menu{
    background-color: #E1DBF0;  ;
  }
  @media screen and (max-width: 768px) {
    body{
      margin-top: 3.5rem;
  }
    .nav__toggler {
      display: block;
      cursor: pointer;
     }
    .nav__brand {
   height: 51px;
    }
  
    .nav__item{
      font-weight: 600;
      font-size: 18px;
    }
    .nav__menu {
  position: fixed;
      top: 0;
      margin-top: 3.5rem;
      left: 0;
      height: 100%;
      width: 51vw;
      background: #3C427B;;
      padding-top: 2rem;
      gap: 2rem;
      flex-direction: column;
      justify-content: start;
      align-items: flex-start;
      text-align: left !important;
      transform: translateX(-100%);
      transition: 0.5s ease-in;
      z-index: 1001;
    }
    .dropdownn{
      margin-left: -15px;
    }
  }
  
  /* Active Class */
  .nav__active {
    transform: translateX(0%);
    
  }
  
  /* Toggle Icon Animation */
  .toggle .line1 {
      transform: rotate(-45deg) translate(-9px, 9px);
    }
    
    .toggle .line2 {
      opacity: 0;
    }
    
    .toggle .line3 {
      transform: rotate(45deg) translate(-4px, -5px);
    }
  