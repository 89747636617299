.reg_form_container{
    width: 100%;
    padding: 0%;
    border: 1px dotted #3C427B;
}
.input-container {
    position: relative;
  }
  
  .reg_input_field {
    padding-right: 30px; /* Adjust this value to leave space for the icon */
  }
  
  .iconn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px; /* Adjust this value to position the icon as per your preference */
    color: #555; /* Adjust the color of the icon */
  }
.reg_form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2px 5%;
    color: #000;
 }
.reg_form_text{
    max-width: 100%;
    margin: auto;
    font-size: 18px;
    background-color: #3C427B;
    border-radius: 0 0 95% 95%; /* top-left, top-right, bottom-right, bottom-left */
    text-align: center;
    padding: 5% 0px;
    color: #fff;
    text-transform: capitalize;
}
.reg_input_field{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 390px;
background-color: #f2f2f2;
}
.reg_label{
    color: #000;
}
.reg_form_container{
/* background-color: black; */
}
.reg_submitbtn{
    font-weight: 500;
    margin: 1rem  auto 15px auto;
    text-transform: uppercase;
}
@media (max-width: 767px) {
.reg_form_container{
    width: 100%;
    margin-top: 1rem;
}
.reg_form_text{
 min-width: 100%;
 font-size: 14px;
}
.reg_input_field{
 width: 80vw;
 }   
}