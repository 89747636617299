.register_fluid{
  padding: 5% 0px;
}
.register_content_container{
  display: flex;
  justify-content: space-between;
  align-items: center;  
}
.register_text_wrapper{
    width: 45%;
}
.reg_form_wrapper{
  width: 35%;
 }

@media (max-width: 767px) {
  .reg_now_heading{
  margin-top: -7px;
  }
  .register_content_container{
flex-direction: column; 
  }
  .register_text_wrapper{
    width: 95%;
}
.reg_form_wrapper{
  width: 95%;
 }
}