.innovation_containerFluid{
    margin-top: 2rem;
    background-color: #F9F9FA;
    padding: 5% 0px;
}
.innovation_content_container{
    display: flex;
    justify-content: space-around;
    margin-top: 1.5rem;
}
.innovation_card_img{
    width: 80%;
    height: 100%;
    margin-top: 2rem;
 
}
.innovation_cards{
    width: 21rem;
    background-color: transparent;
    box-shadow: none;
  border-radius: 40px;
    height: 100%;
    padding: 0px; 
    text-align: left;
    background-color: #fff;
}
.innovation_card_body{
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start;
    margin-right: auto;
    margin-left: 20px;
}
.our-card-heading{
    font-size: 20px;
    font-weight: 500;
    color: #0D134F;
}

@media (max-width: 767px) {
    .innovation_containerFluid{
        margin-top: 1rem;

    }
    .innovation_content_container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    .innovation_cards{
        width: 90%;

    } 
    .our-card-heading{
        font-size: 14px;
        font-weight: 500;
    }
}