.jee_fluid{
    margin-top: 2rem;
}
.Jee_cards_wrapper{
    display: flex;
    justify-content: center;
    gap: 3%;
    align-items: center;
    margin-top: 2rem;
}
.excelinit_content_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    text-align: center;
    margin-top: 5%;
}
.Jee_cards{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #3C427B;
    color: #fff;
    width: 170px;
    height: 160px;
    font-size: 15px;
    font-weight: 500;
}
.yourpath_content_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.success_content_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.yourpath_textwrapper{
    width: 45%;
}
.jee_rect_img{
    width: 100%;
}
.jee_rect_img1{
    min-width: 100%;
}
.yourpath_img{
    width: 65%;
    height: 100%;
}
/*  */
.about_IITJEE_content_container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
   
}
/*  */
@media (max-width: 767px) {
    .jee_fluid{
        margin-top: 1rem;
    }
    .Jee_cards_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap; /* Adjust as needed */
        margin: 1rem auto 0px auto !important;
        overflow: hidden;
        gap: 15px;
    }

    .Jee_cards{
max-width: 150px;
    }
    .jee_card_img{
        height: 35px;
        width: 35px;
    }
    .jee_card_title{
        font-size: 12px;
    }
    /* your-path */

    .yourpath_content_container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
    }
    .success_content_container{
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap: 15px;  
    }
    .yourpath_textwrapper{
        width: 100%;
    }
    .yourpath_img{
        max-width: 90%;
        
    }
    /* about-jee */
    .jee_rect_img{
        max-width: 100% !important;
        margin: auto;
    }
    .jee_rect_img1{
        max-width: 100% !important;
        margin: auto;
    }
}