/* Contact.css */
.contact_top_fluid{
  background-color: #3C427B;
  color: #ffffff;
  padding: 5% 2%;
}
.contact_top_wrapper{
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 80%;
  margin: auto;
}
.ctop_text{
font-size: 18px;
}
.contact_form_container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #3C427B;
  margin-top: 1%;
  padding: 5%;
  color: #fff;
}
.form_heading_wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  margin: 0px auto 2rem auto;
}
.form {
    display: flex;
    width: 100%; /* Adjust the max-width as needed */
    margin: auto;
  }
  .flex_wrapper{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .contact_form_group {
    display: flex;
    flex-direction: column;
    margin-bottom: 2px;
  }
  
  label{
    margin-bottom: 5px;
    color: #fff;
  }
  
  input,
  textarea {
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 10px;
    width: 30vw;
    height: 50px;
    border: none;
    outline: none;
    background-color: #E1DBF0;  
  }
  #message{
    height: 250px;
    max-height: 260px;
  }
  
  .spann {
    color: red;
    font-size: 14px;
    margin-top: 5px;
    display: block;
  }
  
  button {
    padding: 10px 25px;
    background-color: #FF2180;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-left: auto;
    margin-right: 60px;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 700;
  }
  @media (max-width: 767px) {
    .contact_top_wrapper{
width: 90%;
    }
    .ctop_text{
      font-size: 14px ;
      }
      .form_heading_wrapper{
        width: 100%;
        margin: 0px auto 1rem auto;
      }
      .flex_wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      input,
      textarea {
        width: 90vw;
        height: 50px;
  
      }
      #message{
        height: 50px;
        max-height: 60px;
      }
      button {
        padding: 10px 25px;
   margin-right: 5px;
      margin-top: 20px;
        font-size: 16px;
        font-weight: 700;
      }

  }