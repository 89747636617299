.pickcourse_containerFluid{
 margin-top: 2rem;
 background-color: #3C427B;
}
.pickcourse_heading{
  display: flex;
  justify-content: flex-start;
  text-align: left;
  margin-right: auto;
  width: 45%;
}
.slider{
  margin: auto;
  padding: 15px;
}

.pickcourse_cards{
  max-width: 90%;
    background-color: transparent;
    box-shadow: none;
  border-radius: 40px;
    height: 100%;
    padding: 0px; 
    text-align: left;
    margin: auto !important;
    opacity: 1 !important;
 
}
.pickcourse_card_img{
    width: 80%;
    height: 90%;
    margin: 2rem auto 0px auto;
    border-radius: 30px;
}
.pickcourse_card_body{
width: 100%;
text-align: left;
background-color: #3C427B;
color: #fff;
border-radius: 10px;
opacity: 1 !important;
}
@media (max-width: 767px) {
.pickcourse_heading{
  width: 90%;
}
}