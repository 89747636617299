.connect_content_container{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.connect_containerFluid{
    padding: 10% 25px;
}
.connect_form_img{
  width: 95%;
}
.form {
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
margin-top:2rem;
}
.form_heading{
    font-size: 28px;
    font-weight: 700;
}
.form_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
align-items: flex-start;
width: 45%;
text-transform: capitalize;
color: #fff;
margin-left: auto;
}  
  .form_group {
    position: relative;
    display: flex;
   }
   .input_errorbox{
    margin-bottom: 11px;
    display: flex;
    flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  }
  
  .inputfield_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 35px;
    margin-top: 20px;
  }
  
  .input_fields {
    border: 1px solid;
    height: 50px;
    width: 24vw;
    padding: 10px;
    border-radius: 30px;
    border: none;
    padding-top: 25px;
    background-color: #fff;
  color: #000;
  }
  .input_fields:focus {
    outline: none;
    border: none;
    background-color: #fff;
    color: #000;
  }
  
  .labels {
    position: absolute;
    top: 16.5px;
    left: 15px;
    font-size: 15px;
    /* color: #777; */
    pointer-events: none;
    transition: 0.2s;
    color: #000;
  }
  
  .input_fields:focus~label,
  .input_fields:valid~label {
    padding-top: 2.5px;
    top: 2px;
    left: 12px;
    font-size: 12px;
   }
  .submit_btn {
    margin-top: 5px;
    background-color: #FF2180;
    color: #fff;
    font-size: 18px;
    font-family: poppins;
    padding: 7px 20px;
    border-radius: 10px;
  }
  
  .error_message {
      color: red;
    font-size: 12px;
    }
    @media (max-width: 767px) {
  .connect_content_container{
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  gap: 30px;
    }
    .connect_form_img{
      width: 90%;
    }
    .form_wrapper{
  width: 90%;
  margin: auto;
  } 
  .form_heading{
    font-size: 16px;
    font-weight: 700;
} 
.labels {
font-size: 12px;
top: 17px;
}
.input_fields {
width: 100%;
height: 45px;
}
.form_group {
width: 100%;
height: auto;
 }
 .input_errorbox{
  margin-bottom: 0px;
width: 100%;
height: 100%;
}
.submit_btn {
  font-weight: 600;
  margin-top: 10px;
  margin-left: 7px;
  font-size: 14px;
  padding: 7px 12px;

}
    }
  
