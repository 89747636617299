.admission_content_container{
    margin-top: 2rem;
}
.adimission_card{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: #3C427B;
    color: #fff;
    width: 32em;
    padding: 25px;
    height: 380px;
    box-shadow: none;
    border-radius: 30px;
    opacity: 1;
}
.adimission_card_img{
width: 80px;
height: 80px;
margin: auto;
}
.card_heading1{
    font-weight: 500;
    font-size: 20px;
}
.card_heading2{
    font-weight: 700;
    font-size: 28px;
}

.admission_content_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.underline{
    width: 100%;
    height: 1px;
    background-color: lightgray;
}

/*  */
@media (max-width: 767px) {
.admission_content_container{
    flex-direction: column;
    margin-top: 1rem;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 95%;
}
.adimission_card_img{
    margin-bottom: 15px;
}
.adimission_card{
    width: 88vw;
}
.card_heading1{
    font-size: 16px;
}
.card_heading2{
 font-size: 18px;
}
}