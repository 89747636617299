.teacher_container_Fluid{
    margin-top: 5rem;
}
.teacher_content_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }
    .teacher_heading{
        margin-right: auto;
    }
.teachers_images_wrapper{
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}